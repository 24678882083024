import React, { useState } from 'react';
import { Copy, CheckCircle, ExternalLink } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const TokenInfo = () => {
  const [copied, setCopied] = useState(false);
  const contractAddress = "0x1234567890abcdef1234567890abcdef12345678";

  const mockPriceData = Array.from({ length: 30 }, (_, i) => ({
    date: `2024-${String(i + 1).padStart(2, '0')}`,
    price: Math.random() * 2 + 1
  }));

  const copyAddress = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 py-16 space-y-8">
        {/* Header */}
        <div className="flex justify-between items-center">
          <h1 className="text-4xl font-bold text-white">$SET Token</h1>
          <div className="flex gap-4">
            <button onClick={copyAddress} 
                    className="flex items-center gap-2 px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700">
              {copied ? 
                <CheckCircle className="w-5 h-5 text-green-500" /> : 
                <Copy className="w-5 h-5 text-gray-400" />}
              <span className="text-gray-300">Copy Address</span>
            </button>
            <a href="https://etherscan.io" 
               target="_blank" 
               className="flex items-center gap-2 px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 text-gray-300">
              <ExternalLink className="w-5 h-5" />
              View on Etherscan
            </a>
          </div>
        </div>

        {/* Price Chart */}
        <div className="bg-gray-800 rounded-xl p-6">
          <h2 className="text-2xl font-bold text-white mb-6">Price Chart</h2>
          <div className="h-[400px]">
          <iframe height="100%" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src="https://www.geckoterminal.com/solana/pools/93tjgwff5Ac5ThyMi8C4WejVVQq4tuMeMuYW1LEYZ7bu?embed=1&info=0&swaps=0" frameborder="0" allow="clipboard-write" allowfullscreen></iframe>
          </div>
        </div>

        {/* Tokenomics Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-800 rounded-xl p-6">
            <h2 className="text-2xl font-bold text-white mb-6">Tokenomics</h2>
            <div className="space-y-4">
              {[
                { label: 'Total Supply', value: '1,000,000,000 SET' },
                { label: 'Circulating Supply', value: '400,000,000 SET' },
                { label: 'Market Cap', value: '$5,000,000' },
                { label: 'Initial Price', value: '$0.005' }
              ].map((item, index) => (
                <div key={index} className="flex justify-between items-center border-b border-gray-700 pb-2">
                  <span className="text-gray-400">{item.label}</span>
                  <span className="text-white font-medium">{item.value}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-800 rounded-xl p-6">
            <h2 className="text-2xl font-bold text-white mb-6">Distribution</h2>
            <div className="space-y-4">
              {[
                { label: 'Public Sale', percentage: 40 },
                { label: 'Team', percentage: 20 },
                { label: 'Development', percentage: 20 },
                { label: 'Marketing', percentage: 20 }
              ].map((item, index) => (
                <div key={index} className="space-y-2">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-400">{item.label}</span>
                    <span className="text-white font-medium">{item.percentage}%</span>
                  </div>
                  <div className="w-full bg-gray-700 rounded-full h-2">
                    <div
                      className="bg-gradient-to-r from-purple-500 to-blue-500 h-2 rounded-full"
                      style={{ width: `${item.percentage}%` }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenInfo;